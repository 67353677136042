// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  display: flex;
  justify-content: center;
  padding: 2rem;
  section {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
h1 {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

button {
  width: fit-content;
  border-radius: 4px;
  border: 1px solid #D3D3D3;
  padding: 4px;
  font-size: 14px;
  font-weight: 500;
  /* background-color: lightpink; */
  cursor: pointer;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
button:disabled {
  cursor: not-allowed;
}

.button-group {
  display: flex;
  gap: 0.5rem;
  padding-bottom: 0.2rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,aAAa;EACb;IACE,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,WAAW;EACb;AACF;AACA;EACE,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,iCAAiC;EACjC,eAAe;AACjB;AACA;EACE;IACE,4CAA4C;EAC9C;AACF;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".App {\n  display: flex;\n  justify-content: center;\n  padding: 2rem;\n  section {\n    width: 50%;\n    display: flex;\n    flex-direction: column;\n    gap: 0.5rem;\n  }\n}\nh1 {\n  text-align: center;\n}\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\nbutton {\n  width: fit-content;\n  border-radius: 4px;\n  border: 1px solid #D3D3D3;\n  padding: 4px;\n  font-size: 14px;\n  font-weight: 500;\n  /* background-color: lightpink; */\n  cursor: pointer;\n}\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\nbutton:disabled {\n  cursor: not-allowed;\n}\n\n.button-group {\n  display: flex;\n  gap: 0.5rem;\n  padding-bottom: 0.2rem;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
