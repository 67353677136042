export type InitialState = {
  quality: string;
  width: string;
  height: string;
  rt: string;
  raw: string;
  contrast: string;
  brightness: string;
  grayscale: string;
  blur: string;
  sepia: string;
  sharpen: string;
  radius: string;
  ar: string;
  opacity: string;
  cm: string;
  text: string;
  background: string;
};

export class Node<T> {
  public data: T;
  public prev: Node<T> | null = null;
  public next: Node<T> | null = null;

  constructor(data: T) {
    this.data = data;
  }
}

export class HistoryManager<T> {
  private current: Node<T> | null = null;
  private initialStateCopy: Node<T> | null = null;

  constructor(initialState: T) {
    this.current = new Node(initialState);
    this.initialStateCopy = new Node(initialState);
  }

  push(newState: T): void {
    const newNode = new Node(newState);
    if (this.current) {
      this.current.next = null;
      newNode.prev = this.current;
    }
    this.current = newNode;
    return;
  }

  reset(): any {
    this.current = this.initialStateCopy;
    return this.current?.data;
  }

  undo(): T | null {
    if (this.current && this.current.prev) {
      const currentNode = this.current;
      this.current = this.current.prev;
      this.current.next = currentNode;
      return this.current.data;
    }
    return null;
  }

  redo(): T | null {
    if (this.current && this.current.next) {
      this.current = this.current.next;
      return this.current.data;
    }
    return null;
  }

  getCurrentState(): T | null {
    return this.current ? this.current.data : null;
  }

  isUndoActive(): boolean {
    return this.current && this.current.prev ? true : false;
  }
  isRedoActive(): boolean {
    return this.current && this.current.next ? true : false;
  }

  isResetAtive(): boolean {
    return this.current && (this.current.next || this.current.prev)
      ? true
      : false;
  }
}
