import {IKImage} from 'imagekitio-react';
import {useState} from 'react';
import {HistoryManager, InitialState} from '../helpers/historymanager';
import '../App.css'
import '../index.css';

const urlEndpoint = 'https://ik.imagekit.io/saurabhvani/';
const imageName = 'ford gt.jpeg';

const initial = {
  quality: '100',
  width: '400',
  height: '400',
  rt: '0',
  raw: '',
  contrast: '100',
  brightness: '100',
  grayscale: '0',
  blur: '0',
  sepia: '0',
  sharpen: '0',
  radius: '0',
  ar: 'undefined',
  opacity: '100',
  text: '',
  cm: 'auto',
  background: 'undefined'
};
const historyManager = new HistoryManager<InitialState>(initial);
function ImageKitComponent() {
  const [transformation, setTransformation] = useState<InitialState>(initial);
  const onChangeEvent = (e: any) => {
    if (e.target.name === 'height') {
      transformation.ar = 'undefined';
    }
    historyManager.push({
      ...transformation,
      [e.target.name]: e.target.value,
    });
    setTransformation(historyManager.getCurrentState() || initial);
  };
  const onTextChange = (e: any) => {
    historyManager.push({
      ...transformation,
      text: e.target.value,
      raw: e.target.value
        ? `l-text,i-${e.target.value},co-white,pa-10,fs-20,tg-b_i,l-end`
        : '',
    });
    setTransformation(historyManager.getCurrentState() || initial);
  };

  const onUndoClicked = () => {
    setTransformation(historyManager.undo() || initial);
  };
  const onRedoClicked = () => {
    setTransformation(historyManager.redo() || initial);
  };
  const onResetClicked = () => {
    setTransformation({...historyManager.reset()});
  };

  const onDownloadImageClicked = async () => {
    try {
      const imageElement: any = document.getElementById('image');
      const response = await fetch(imageElement.src);
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = imageName;
      link.click();
      URL.revokeObjectURL(link.href); // Clean up the object URL
    } catch (error) {
      console.error('Image download failed:', error);
    }
  };

  return (
    <div className='p-50'>
      <h1>ImageKit.io React</h1>
      <div className="App">
        <section>
          <div className='size-wrap'>
          <div className='width'>
            <label htmlFor="width"> Width </label>
            <input
              id="width"
              onChange={onChangeEvent}
              name="width"
              value={transformation.width}
            />
          </div>
          <div className='height'>
            <label htmlFor="height"> Height </label>
            <input
              id="height"
              onChange={onChangeEvent}
              name="height"
              value={
                transformation.ar !== 'undefined' ? '' : transformation.height
              }
            />
          </div>
          <div className='imgtxt'>
            <label htmlFor="overlaytext">Text Above Image </label>
            <input
              onChange={onTextChange}
              id="overlaytext"
              value={transformation.text}
            />
          </div>
          </div>
          <div className='radio-btn'>
            <label htmlFor="aspect-ratio"> Aspect Ratio: </label>
            <div className='radio-btnwap'>
            <span>
              <input
                type="radio"
                value={'undefined'}
                checked={transformation.ar === 'undefined'}
                name="ar"
                id="none"
                onChange={onChangeEvent}
              />
              <label htmlFor="none">None</label>
            </span>
            <span>
              <input
                type="radio"
                value={'1-1'}
                checked={transformation.ar === '1-1'}
                name="ar"
                id="1-1"
                onChange={onChangeEvent}
              />
              <label htmlFor="1-1">1-1</label>
            </span>
            <span>
              <input
                type="radio"
                value={'4-3'}
                checked={transformation.ar === '4-3'}
                name="ar"
                id="4-3"
                onChange={onChangeEvent}
              />
              <label htmlFor="4-3">4-3</label>
            </span>
            <span>
              <input
                id="16-9"
                type="radio"
                value={'16-9'}
                checked={transformation.ar === '16-9'}
                name="ar"
                onChange={onChangeEvent}
              />
              <label htmlFor="16-9">16-9</label>
            </span>
            </div>
          </div>
          <div className='radio-btn'>
            <label htmlFor="aspect-ratio"> Crop Mode: </label>
            <div className='radio-btnwap'>
            <span>
              <input
                type="radio"
                value={'auto'}
                checked={transformation.cm === 'auto'}
                name="cm"
                id="auto"
                onChange={onChangeEvent}
              />
              <label htmlFor="auto">Auto</label>
            </span>
            <span>
              <input
                type="radio"
                value={'pad_resize'}
                checked={transformation.cm === 'pad_resize'}
                name="cm"
                id="pad_resize"
                onChange={onChangeEvent}
              />
              <label htmlFor="pad_resize">Pad Resize</label>
            </span>
            </div>
          </div>
          <div className='radio-btn'>
            <label htmlFor="aspect-ratio"> Background: </label>
            <div className='radio-btnwap'>
            <span>
              <input
                type="radio"
                value={'undefined'}
                checked={transformation.background === 'undefined'}
                name="background"
                id="bg-none"
                onChange={onChangeEvent}
              />
              <label htmlFor="bg-none">None</label>
            </span>
            <span>
              <input
                type="radio"
                value={'blurred'}
                checked={transformation.background === 'blurred'}
                name="background"
                id="blurred"
                onChange={onChangeEvent}
              />
              <label htmlFor="blurred">Blur</label>
            </span>
            <span>
              <input
                type="radio"
                value={'black'}
                checked={transformation.background === 'black'}
                name="background"
                id="black"
                onChange={onChangeEvent}
              />
              <label htmlFor="black">Black</label>
            </span>
            </div>
          </div>
          <div className='flex'>
            <label htmlFor="quality">Quality: </label>
            <input
              type="range"
              min="0"
              max="100"
              name="quality"
              onChange={onChangeEvent}
              value={transformation.quality}
              id="quality"
            />
            {`(${transformation.quality})`}
          </div>
          <div className='flex'>
            <label htmlFor="rt"> Rotate: </label>
            <input
              type="range"
              min="0"
              max="360"
              name="rt"
              onChange={onChangeEvent}
              value={transformation.rt}
              id="rt"
            />
            {`(${transformation.rt})`}
          </div>
          <div className='flex'>
            <label htmlFor="rotation"> Rotation: </label>
            <span>
              <input
                type="radio"
                value={0}
                checked={transformation.rt === '0'}
                name="rt"
                onChange={onChangeEvent}
              />
              <label htmlFor="rotation">0 </label>
            </span>
            <span>
              <input
                type="radio"
                value={90}
                checked={transformation.rt === '90'}
                name="rt"
                onChange={onChangeEvent}
              />
              <label>90 </label>
            </span>
            <span>
              <input
                type="radio"
                value={180}
                checked={transformation.rt === '180'}
                name="rt"
                onChange={onChangeEvent}
              />
              <label>180 </label>
            </span>
            <span>
              <input
                type="radio"
                value={270}
                checked={transformation.rt === '270'}
                name="rt"
                onChange={onChangeEvent}
              />
              <label>270 </label>
            </span>
          </div>
          <div className='flex'>
            <label htmlFor="contrast"> Contrast: </label>
            <input
              type="range"
              min="0"
              max="200"
              name="contrast"
              onChange={onChangeEvent}
              value={transformation.contrast}
              id="contrast"
            />
            {`(${transformation.contrast})`}
          </div>
          <div className='flex'>
            <label htmlFor="brightness"> Brightness: </label>
            <input
              type="range"
              min="0"
              max="200"
              name="brightness"
              onChange={onChangeEvent}
              value={transformation.brightness}
              id="brightness"
            />
            {`(${transformation.brightness})`}
          </div>
          <div className='flex'>
            <label htmlFor="grayscale"> Grayscale: </label>
            <input
              type="range"
              min="0"
              max="100"
              name="grayscale"
              onChange={onChangeEvent}
              value={transformation.grayscale}
              id="grayscale"
            />
            {`(${transformation.grayscale})`}
          </div>
          <div className='flex'>
            <label htmlFor="blur"> Blur: </label>
            <input
              type="range"
              min="0"
              max="100"
              name="blur"
              onChange={onChangeEvent}
              value={transformation.blur}
              id="blur"
            />
            {`(${transformation.blur})`}
          </div>
          <div className='flex'>
            <label htmlFor="sepia"> Sepia: </label>
            <input
              type="range"
              min="0"
              max="100"
              name="sepia"
              onChange={onChangeEvent}
              value={transformation.sepia}
              id="sepia"
            />
            {`(${transformation.sepia})`}
          </div>
          <div className='flex'>
            <label htmlFor="sharpen"> Sharpeness: </label>
            <input
              type="range"
              min="0"
              max="100"
              name="sharpen"
              onChange={onChangeEvent}
              value={transformation.sharpen}
              id="sharpen"
            />
            {`(${transformation.sharpen})`}
          </div>
       
          <div className='flex'>
            <label htmlFor="radius"> Radius: </label>
            <input
              type="range"
              min="0"
              max="200"
              name="radius"
              onChange={onChangeEvent}
              value={transformation.radius}
              id="radius"
            />
            {`(${transformation.radius === '200' ? 'Max' : transformation.radius})`}
          </div>
          <div className='flex'>
            <label htmlFor="opacity"> Opacity: </label>
            <input
              type="range"
              min="0"
              max="100"
              name="opacity"
              onChange={onChangeEvent}
              value={transformation.opacity}
              id="opacity"
            />
            {`(${transformation.opacity})`}
          </div>

        </section>
        <div className='image-wrap' >
     
          <IKImage
            id="image"
            urlEndpoint={urlEndpoint}
            path={imageName}
            width={transformation.width}
            alt="image with imagekit"
            lqip={{active: true, quality: 20}}
            transformation={[
              {
                radius:
                  transformation.radius === '200'
                    ? 'max'
                    : transformation.radius,
              },
              {
                quality: transformation.quality,
                width: transformation.width,
                height:
                  transformation.ar === 'undefined'
                    ? transformation.height
                    : 'auto',
                blur:
                  transformation.blur === '0' ? undefined : transformation.blur,
                ar:
                  transformation.ar === 'undefined'
                    ? undefined
                    : transformation.ar,
                'e-sharpen': transformation.sharpen,
                cm: transformation.cm === 'auto' ? undefined : transformation.cm,
                background: transformation.cm === 'auto' || transformation.background === 'undefined' ? undefined : transformation.background,
              },
              {
                raw: transformation.raw,
                o: transformation.opacity,
                rt: transformation.rt,
              },
            ]}
            style={{
              filter: `contrast(${transformation.contrast}%) brightness(${transformation.brightness}%) grayscale(${transformation.grayscale}%) sepia(${transformation.sepia}%)`,
            }}
          />
               <div className="button-group">
            <button
              onClick={onUndoClicked}
              disabled={!historyManager.isUndoActive()}>
              Undo
            </button>
            <button
              onClick={onRedoClicked}
              disabled={!historyManager.isRedoActive()}>
              Redo
            </button>
            <button
              onClick={onResetClicked}
              disabled={!historyManager.isResetAtive()}>
              Reset
            </button>
            <button onClick={onDownloadImageClicked}>Download Image</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageKitComponent;
