const FocusExample = () => {
  return (
    <>
        <picture    >
            <source media="(max-width: 799px)" srcSet="https://ik.imagekit.io/saurabhvani/default-image.jpg?tr=w-344,h-475,fo-custom,cm-extract" />
            <source media="(min-width: 800px)" srcSet="https://ik.imagekit.io/saurabhvani/default-image.jpg" />
            <img className="w-h-100" src="https://ik.imagekit.io/saurabhvani/default-image.jpg" alt="Testing focus" />
        </picture>
     
    </>
  );
};

export default FocusExample;
