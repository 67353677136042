
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ImageKitComponent from './component/imagekit';
import FocusExample from './component/focus-example';
function App() {
 
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<ImageKitComponent />} />
      <Route path="/focus-with-srcset" element={<FocusExample />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
